
const videoFullBarba = () => {

  if (window.location.pathname === '/en/raffle-ice-bcn/' || window.location.pathname === '/en/book-a-meeting-ice-bcn/') {

    console.log("ICE BOOK CONECTED")

    // Forbidden emails

    const errorMessage = document.getElementById('error-message');
    errorMessage.style.display = 'none';

    const forbiddenDomains = [
      'gmail.com',
      'outlook.com',
      'yahoo.com',
      'hotmail.com',
      'mail.com',
    ];

    


    document
      .getElementById('form-salesforce')
      .addEventListener('submit', function (event) {
        
        // selectErrorPaisBook(event)
        const emailField = document.getElementById('email');
        //alert(emailField.value)

        const email = emailField.value.trim();
        const domain = email.includes('@') ? email.split('@')[1].toLowerCase() : null;

        if (domain && forbiddenDomains.includes(domain)){
          errorMessage.style.display = 'block';
          event.preventDefault()
        } else{
          errorMessage.style.display = 'none';
        }


      })
  }
  
  
};

export { videoFullBarba };