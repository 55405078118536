import barba from '@barba/core'
import { cargaInicialHero } from '../../../js/scriptHero.js'
import { iconsHero } from '../../../js/iconsVideosHome.js'
import { activeHeader } from '../../../js/activeHeader.js'
import { cargaInicialHeroMobile } from '../../../js/scriptHeroMobile.js'
import { addOwlCarouselHeaderScriptAndStart } from '../../../js/owlCarousel.js'
import { modalesCDN } from '../../../js/modalesBarba.js'
import { tiltCDN } from '../../../js/tiltBarba.js'
import { videoFullBarba } from '../../../js/videFullBarba.js'

// Crea una instancia de la clase cargaInicialHero
// const cargaInicialHero = new cargaInicialHero()

class Barba {
  constructor () {
    this.initBarba()
  }

  initBarba () {
    const TH = this

    barba.init({
      debug: false,
      cacheIgnore: false,
      prefetchIgnore: false,
      timeout: 15000,
      transitions: [
        {
          name: 'default',
          leave ({ current, next, trigger }) {
            current.container.classList.remove('barba-container--fadein')
            current.container.classList.toggle('barba-container--fadeout')

            if (document.getElementsByClassName('header')[0].classList.contains('scrolled')) {
              window.scrollTo(0, 0)
              const async = this.async()
              setTimeout(() => { async() }, 400)
            }
          },
          enter ({ current, next, trigger }) {
            next.container.classList.remove('barba-container--fadeout')
            next.container.classList.toggle('barba-container--fadein')
          },
          once ({ current, next, trigger }) {
            try {
              TH.loadElementInpage()
            } catch (error) {
              console.log(error)
            }

            window.scrollTo(0, 0)
            window.requestAnimationFrame(() => {
              TH.setBodyClasses(next.container)
              TH.setTranslationLinks(next.container)
              TH.mobileMenu()
              TH.updateCurrentHighlightedMenu(next.html)

              // Modules
              // COMENTADO POR ERROR
              // window.modulesLoader.loadModules()

              // Google Analytics
              if (typeof window.ga === 'function') window.ga('send', 'pageview', window.location.pathname)

            })
          },
          after ({ current, next, trigger }) {
            try {
              TH.loadElementInpage()
            } catch (error) {
              console.log(error)
            }
          }
        }
      ],
      prevent: ({ el }) => {
        const ignoreClasses = ['ab-item', 'another-class-here'] // Additional (besides .js-barba-prevent) ignore links with these classes (ab-item is for wp admin toolbar links)

        if (/.pdf/.test(el.href.toLowerCase())) return true
        else if (el.classList && el.classList.contains('js-barba-prevent')) return true
        if (el.dataset && el.dataset.lightbox) return true

        for (let i = 0; i < ignoreClasses.length; i++) {
          if (el.classList.contains(ignoreClasses[i])) return true
        }
      },
      requestError: (trigger, action, url, response) => {
        console.log('REQUEST ERROR')
        console.log(url)
        console.log(response)
      }
    })
  }

  loadElementInpage () {
    // Función de Active
    activeHeader()

    if (window.screen.width < 577) cargaInicialHeroMobile()
    else cargaInicialHero()

    iconsHero()
    videoFullBarba()

    // TILT
    tiltCDN()

    // Modales
    modalesCDN()

    // Carousel
    addOwlCarouselHeaderScriptAndStart()

    // WP admin bar
    this.fixWpAdminBar()
    this.fixLngBar()
    this.fixCanonicalTag()
  }

  setBodyClasses (e) {
    const skipClasses = [''] // Add the classes you want to skip
    const currentBodyClasses = document.querySelector('body').classList
    const newBodyClasses = e.querySelector('#body-classes').value
    const splittedNewBodyClasses = newBodyClasses.split(' ')

    for (var i = 0; i < currentBodyClasses.length; i += 1) {
      if (newBodyClasses.indexOf(currentBodyClasses[i]) > -1) skipClasses.push(currentBodyClasses[i])
    }

    Array.from(currentBodyClasses).forEach((bodyClass) => {
      // Don't remove class if it exists in skipClasses
      if (!skipClasses.includes(bodyClass)) currentBodyClasses.remove(bodyClass)
    })

    for (let s = 0; s < splittedNewBodyClasses.length; s++) {
      document.querySelector('body').classList.add(splittedNewBodyClasses[s])
    }
  }

  setTranslationLinks (e) {
    'use strict'

    const linksData = e.getAttribute('data-translation-links')

    if (linksData) {
      const links = JSON.parse(linksData)

      if (links.constructor === Array && links.length > 0) {
        for (let s = 0; s < links.length; s++) {
          const li = document.querySelector('.header__languages ul li:nth-child(' + (s + 1) + ') a')

          if (li !== null) li.setAttribute('href', links[s])
        }
      }
    }
  }

  mobileMenu () {
    const mobileMenuToogler = document.getElementById('mobile-menu-toogle')

    if (mobileMenuToogler) mobileMenuToogler.checked = false
  }

  /**
   * Parses the DOM structure of the BarbaJS fetch, and applies same classes on the header in DOM.
   * @param {String} htmlString - The new page html in string format from barbaJs
   */
  updateCurrentHighlightedMenu (htmlString) {
    const html = new window.DOMParser().parseFromString(htmlString, 'text/html')

    if ('body' in html === false || html.body.childNodes.length < 1) return

    // First remove current classes from all menu items and sub menus.
    for (const el of Array.from(document.querySelectorAll('.menu-item'))) {
      el.classList.remove('current-menu-item')
      el.classList.remove('current-menu-parent')
      el.classList.remove('current-menu-ancestor')
    }

    // Get the new current menu items and parents and set the appropiate classes.
    let newEl

    for (const el of Array.from(html.querySelectorAll('.current-menu-item'))) {
      if (el.id) {
        newEl = document.querySelector(`#${el.id}`)

        if (newEl !== null) newEl.classList.add('current-menu-item')
      }
    }

    for (const el of Array.from(html.querySelectorAll('.current-menu-parent'))) {
      if (el.id) {
        newEl = document.querySelector(`#${el.id}`)

        if (newEl !== null) newEl.classList.add('current-menu-parent')
      }
    }

    for (const el of Array.from(html.querySelectorAll('.current-menu-ancestor'))) {
      if (el.id) {
        newEl = document.querySelector(`#${el.id}`)

        if (newEl !== null) newEl.classList.add('current-menu-ancestor')
      }
    }
  }

  fixWpAdminBar () {
    if (!document.querySelector('#wpadminbar')) return

    const xmlhttp = new window.XMLHttpRequest() // eslint-disable-line
    const url = encodeURI(scripts_ajax_variables.ajax_url) // eslint-disable-line
    const params = 'action=prt_fix_barbajs_wp_admin&location=' + window.location.href

    xmlhttp.open('POST', url, true) // set true for async, false for sync request
    xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    xmlhttp.send(params) // or null, if no parameters are passed
    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
        try {
          var response = JSON.parse(xmlhttp.responseText)

          if (response && response.success === true) {
            const adminMenuLinks = Array.from(document.querySelector('.ab-top-menu').querySelectorAll('a'))

            for (const adminLink of adminMenuLinks) {
              const mregex = /post=(\d+)&/g
              adminLink.classList.add('js-barba-prevent')
              const href = adminLink.getAttribute('href')
              const groups = mregex.exec(href)

              if (groups && groups.length >= 2) adminLink.setAttribute('href', href.replace(groups[1], response.pageId))
              else {
                const chunks = href.split('?url=')

                if (chunks.length === 2) adminLink.setAttribute('href', chunks[0] + '?url=' + encodeURIComponent(window.location.href))
              }
            }
          }
        } catch (error) { console.log(error) }
      }
    }
  }

  fixLngBar(){
    $.each($(".language-selector a"), function(_,elemnt){
      if(elemnt.innerHTML == "en") elemnt.href = window.location.href.replace("/es/", "/en/");
      else if(elemnt.innerHTML == "es") elemnt.href = window.location.href.replace("/en/", "/es/");
    });
  }

  fixCanonicalTag(){
    const INDEXABLES = [
      window.location.pathname,
      `/en/`,
      `/es/`,
      `/en/personal/`,
      `/es/personal/`,
      `/en/business/`,
      `/es/business/`,
      `/es/personal/kuady-card/`,
      `/en/personal/kuady-card/`,
      `/es/personal/about-us/`,
      `/en/personal/about-us/`,
      `/es/personal/send-money/`,
      `/en/personal/send-money/`,
      `/es/personal/pay-online/`,
      `/en/personal/pay-online/`
    ]

    if (INDEXABLES.includes(window.location.pathname)) document.querySelector('[rel="canonical"]').setAttribute("href", window.location.href)
    else document.querySelector('[rel="canonical"]').setAttribute("href", window.location.origin)
  }
}

export default Barba
